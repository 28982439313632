import { useForm } from "react-hook-form";
import { User } from "../models/user";
import { SignUpCredentials, resendVerificationEmail, verifyAccount } from "../network/user_api";
import * as UserApi from "../network/user_api";
import {Modal, ModalContent, Tab, ModalFooter, ModalHeader, Button, ModalBody, Tooltip, Input, useDisclosure, Tabs, Popover, PopoverTrigger, PopoverContent} from "@nextui-org/react"
import styles from "../styles/utils.module.css";
import { useState } from "react";
import MailIcon from "./Icons/MailIcon";
import UsernameIcon from "./Icons/UsernameIcon";
import PasswordIcon from "./Icons/PasswordIcon";
import OrganizationIcon from "./Icons/OrganizationIcon";

interface SignUpModalProps {
    onDismiss: () => void,
    onSignUpSuccessful: (user : User) => void,
    organizationSetup: boolean,
}


const SignUpModal = ({onDismiss, onSignUpSuccessful, organizationSetup}: SignUpModalProps) => {

    const [emailInput, setEmailInput] = useState<string>();
    const [usernameInput, setusernameInput] = useState("");
    const [passwordInput, setPasswordInput] = useState("");
    const [organizationInput, setOrganizationInput] = useState("");
    const [submitting, setSubmitting] = useState(false);

    const [creatingNewOrg, setCreatingNewOrg] = useState(false);
    
    const [usernameError, setusernameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [organizationError, setOrganizationError] = useState("");
    const [generalError, setGeneralError] = useState("");

    const [verificationCodeInput, setVerificationCodeInput] = useState("");
    const [verificationErrorMessage, setVerificationErrorMessage] = useState("");

    const [resendEmailLocked, setResendEmailLocked] = useState(false);

    const [verificationStep, setVerificationStep] = useState(false);

    async function emailCooldown() {
        setResendEmailLocked(true);
        await new Promise(f => setTimeout(f, 5000));
        setResendEmailLocked(false);
    }

    async function onSubmit(credentials : SignUpCredentials) {
        setSubmitting(true);

        try {
            const newUser = await UserApi.signup(credentials);

            onSignUpSuccessful(newUser);
            return true;
        } catch (error: any) {
            setGeneralError(error.message);
            console.log(error);
            return false;
        } finally {
            setSubmitting(false);
        }
    }

    const signupUi = 
    <>
              <ModalHeader className="flex flex-col gap-1" style={{color:"white"}}>Sign Up</ModalHeader>
              <ModalBody style={{caretColor:'white'}}>
              <Input isRequired={true} isInvalid={(emailError != "")} endContent={<MailIcon style={{color:'white'}}/>}  errorMessage={emailError != "" && (emailError)} minLength={3} maxLength={256} type="email" label="Email" placeholder="Enter your email" required onChange={(e) => {setEmailInput(e.target.value); setEmailError("");}} />
              <Input isRequired={true} isInvalid={(usernameError != "")} endContent={<UsernameIcon style={{color:'white'}}/>} errorMessage={usernameError != "" && (usernameError)} type="username" label="Username" minLength={3} maxLength={64} placeholder="Enter your username" required onChange={(e) => {setusernameInput(e.target.value); setusernameError("");}} />
              <Input isRequired={true} isInvalid={(passwordError != "")} endContent={<PasswordIcon style={{color:'white'}}/>} errorMessage={passwordError != "" && (passwordError)} type="password" minLength={3} maxLength={256} label="Password" placeholder="Enter your password" required onChange={(e) => {setPasswordInput(e.target.value); setPasswordError("");}} />
              {false && <Tabs style={{justifyContent:'center', margin:'auto'}} onSelectionChange={(e) => {setCreatingNewOrg(e == "Not Join");}}>
                <Tab key={"Join"} title="Join An Organization">
                    <Input isInvalid={(organizationError != "")} endContent={<OrganizationIcon style={{color:'white'}}/>} errorMessage={organizationError != "" && (organizationError)} type="organization" label="Organization (Optional)" placeholder="Enter your organization's join code" required onChange={(e) => {setOrganizationInput(e.target.value); setOrganizationError("");}} />
                </Tab>
                
                {false && <Tab key={"Not Join"} title="Create An Organization">
                    <Input isInvalid={(organizationError != "")} endContent={<OrganizationIcon style={{color:'white'}}/>} errorMessage={organizationError != "" && (organizationError)} type="organization" label="Organization Name" placeholder="Enter your new organization's name" required onChange={(e) => {setOrganizationInput(e.target.value); setOrganizationError("");}} />
                </Tab>}
              </Tabs>}

              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onDismiss}>
                  Close
                </Button>
                <Button isDisabled={emailInput?.trim() === "" || usernameInput.trim() === "" || passwordInput.trim() === ""} isLoading={submitting} color="primary" onPress={async () => {
                    try {
                        let emailRegex = /\S+@\S+\.\S+/;
                        let issue = false;

                        if (emailInput?.trim() === ""){
                            setEmailError("Email cannot be empty");
                            issue = true;
                        }

                        if (emailInput !== "" && !emailRegex.test(emailInput as string)){
                            setEmailError("Invalid email");
                            issue = true;
                        }

                        if (!emailInput?.endsWith("friscoisd.org")){
                            setEmailError("Email must be one ending in friscoisd.org");
                            issue = true;
                        }

                        if (usernameInput.trim() === ""){
                            setusernameError("Username cannot be empty");
                            issue = true;
                        }

                        if (passwordInput.trim() === ""){
                            setPasswordError("Password cannot be empty");
                            issue = true;
                        }

                        if (issue){
                            return;
                        }

                        let processedOrgInput = organizationInput;

                        if (organizationInput.trim() == ""){
                            processedOrgInput = "NULLL";
                        }
                        
                        if (creatingNewOrg){
                            await UserApi.signup({ email: emailInput?.trim() || "", username: usernameInput.trim(), password: passwordInput.trim(), organization: processedOrgInput.trim(), newOrganizationName: organizationInput.trim()});
                        }else{
                            await UserApi.signup({ email: emailInput?.trim() || "", username: usernameInput.trim(), password: passwordInput.trim(), organization: processedOrgInput.trim()});
                        }

                        setVerificationStep(true);
                    } catch (error:any) {
                        setGeneralError(error.message);
                    } 
                    

                    

                }}>
                  Sign Up
                </Button>
                {generalError !== "" &&
                <Tooltip style={{color:'white'}} content={generalError}>
                    <Button color="danger">Error</Button>
                </Tooltip>}
                
              </ModalFooter>
            </>

    const verificationUi = 
    <>
        <ModalHeader className="flex flex-col gap-1" style={{color:"white"}}>Verify Your Email</ModalHeader>
              <ModalBody>
                <p style={{color:'white'}}>We've sent an email with a verification code to {emailInput?.trim()}</p>
                <p style={{color:'white'}}>If you don't see an email, wait a few moments, check your junk mail, or resend it below</p>
              <Input defaultValue="" isRequired={true} isInvalid={(verificationErrorMessage !== "")} endContent={<PasswordIcon style={{color:'white'}}/>} type="text" minLength={5} maxLength={5} label="Verification Code" placeholder="Enter the verification code sent to you" required onChange={(e) => {setVerificationCodeInput(e.target.value)}} />
              <Button onClick={() => {
                resendVerificationEmail({email:emailInput || ""});
                emailCooldown();
              }} isDisabled={resendEmailLocked} style={{maxWidth:'35%'}} size="sm">Resend Email</Button>

              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onDismiss}>
                  Close
                </Button>
                <Button isDisabled={verificationCodeInput.trim().length !== 5} isLoading={submitting} style={{color:'white'}} color="success" onPress={async () => {
                    try {
                        const res = await verifyAccount({email: emailInput?.trim() || "", verificationCode: verificationCodeInput});
                        onSignUpSuccessful(res as unknown as User);
                    } catch (error:any) {
                        setVerificationErrorMessage(error.message);
                    }
                }}>
                  Verify
                </Button>
              </ModalFooter>
    </>

    return (
        <>
        <Modal isOpen={true} onClose={onDismiss}>
        <ModalContent>
          {(onClose) => (
            verificationStep ? verificationUi : signupUi
          )}
        </ModalContent>
      </Modal>
        </>

        

    );
} 

/*
<Modal show onHide={onDismiss}>
        <Modal.Header closeButton>
            <Modal.Title>
                Sign Up
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>
            
            <Form onSubmit={handleSubmit(onSubmit)}>
                <TextInputField
                    name="username"
                    label="Username"
                    type="text"
                    placeholder="Username"
                    register={register}
                    registerOptions={{ required: "Required" }}
                    error={errors.username}
                />
                <TextInputField
                    name="email"
                    label="Email"
                    type="email"
                    placeholder="Email"
                    register={register}
                    registerOptions={{ required: "Required" }}
                    error={errors.email}
                />
                <TextInputField
                    name="password"
                    label="Password"
                    type="password"
                    placeholder="Password"
                    register={register}
                    registerOptions={{ required: "Required" }}
                    error={errors.password}
                />
                <Button
                    type="submit"
                    disabled={isSubmitting}
                    style={{width:"100%"}}>
                    Sign Up
                </Button>
            </Form>
        </Modal.Body>

    </Modal>
    */

export default SignUpModal;