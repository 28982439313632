import ScienceIcon from '../components/Icons/ScienceIcon';
import LiteratureIcon from '../components/Icons/LiteratureIcon';
import MathIcon from '../components/Icons/MathIcon';
import SocialScienceIcon from '../components/Icons/SocialScienceIcon';
import ArtIcon from '../components/Icons/ArtIcon';
import MusicIcon from '../components/Icons/MusicIcon';
import EconIcon from '../components/Icons/EconIcon';
import { ReactNode } from 'react';
import { Chip } from '@nextui-org/react';
 
export const grabIdentifier = (category: String) : any => {
    if (category === undefined) {
        return undefined;
    }

    switch (category.toString().toLowerCase()) {
        case "science": {
            return "science";
        }

        case "lit":{
            return "lit";
        }
        
        case "literature": {
            return "lit";
        }

        case "math": {
            return "math";
        }

        case "mathematics": {
          return "math";
      }

        case "socialscience": {
            return "socialScience";
        }

        case "social science": {
          return "socialScience";
      }

        case "art": {
            return "art";
        }

        case "music": {
            return "music";
        }

        case "econ": {
            return "econ";
        }

        case "economics": {
          return "econ";
        }
    }
}

export const grabCompetitiveIdentifier = (category: String) : any => {
  if (category === undefined) {
      return undefined;
  }

  switch (category.toString().toLowerCase()) {
      case "science": {
          return "Science";
      }

      case "lit":{
          return "Lit";
      }
      
      case "literature": {
          return "Lit";
      }

      case "math": {
          return "Math";
      }

      case "mathematics": {
        return "Math";
    }

      case "socialscience": {
          return "SocialScience";
      }

      case "social science": {
        return "SocialScience";
    }

      case "art": {
          return "Art";
      }

      case "music": {
          return "Music";
      }

      case "econ": {
          return "Econ";
      }

      case "economics": {
        return "Econ";
      }

      default:
        return category.toString();
  }
}

export const getCategoryIcon = (category: String) : any => {
    switch (grabIdentifier(category)) {
        case "science": {
            return <ScienceIcon />;
        }
        
        case "lit": {
            return <LiteratureIcon />;
        }

        case "math": {
            return <MathIcon />;
        }

        case "socialScience": {
            return <SocialScienceIcon />;
        }

        case "art": {
            return <ArtIcon/>;
        }

        case "music": {
            return <MusicIcon/>;
        }

        case "econ": {
            return <EconIcon/>;
        }
    }
}

/*

<Chip startContent={<SocialScienceIcon/>} color="success" style={{color:'white', marginBottom: '0.5rem', marginRight:'0.4rem'}}>
                      Social Science
                    </Chip>
                    <Chip startContent={<ScienceIcon/>} color="primary" style={{color:'white', marginBottom: '0.5rem', marginRight:'0.4rem'}}>
                      Science
                    </Chip>
                    <Chip startContent={<MathIcon/>} style={{color:'white', backgroundColor:'#66101F', marginBottom: '0.5rem', marginRight:'0.4rem'}}>
                      Mathematics
                    </Chip>
                    <Chip startContent={<LiteratureIcon/>} color="default" style={{color:'white', backgroundColor:'#403D58', marginBottom: '0.5rem', marginRight:'0.4rem'}}>
                      Literature
                    </Chip>
                    <Chip startContent={<EconIcon/>} style={{color:'white', backgroundColor: '#EA9010', marginBottom: '0.5rem', marginRight:'0.4rem'}}>
                      Economics
                    </Chip>
                    <Chip className="bg-gradient-to-tr" startContent={<ArtIcon/>} style={{color:'white', backgroundColor:'#B85B19', marginBottom: '0.5rem', marginRight:'0.4rem'}}>
                      Art
                    </Chip>
                    <Chip startContent={<MusicIcon/>} style={{color:'white', backgroundColor: '#175676', marginRight:'0.4rem'}}>
                      Music
                    </Chip>

*/

export const getCategoryChip = (category: String) : any => {
     switch (category.toLowerCase()) {
        case "socialscience":
            return (<Chip startContent={<SocialScienceIcon/>} color="success" style={{color:'white', marginBottom: '0.5rem', marginRight:'0.4rem'}}>
            Social Science
        </Chip>);
        case "social science":
          return (<Chip startContent={<SocialScienceIcon/>} color="success" style={{color:'white', marginBottom: '0.5rem', marginRight:'0.4rem'}}>
          Social Science
      </Chip>);
        case "science":
            return (<Chip startContent={<ScienceIcon/>} color="primary" style={{color:'white', marginBottom: '0.5rem', marginRight:'0.4rem'}}>
            Science
          </Chip>);
          case "math":
            return (<Chip startContent={<MathIcon/>} style={{color:'white', backgroundColor:'#66101F', marginBottom: '0.5rem', marginRight:'0.4rem'}}>
            Mathematics
          </Chip>);
           case "mathematics":
            return (<Chip startContent={<MathIcon/>} style={{color:'white', backgroundColor:'#66101F', marginBottom: '0.5rem', marginRight:'0.4rem'}}>
            Mathematics
          </Chip>);
          case "lit":
            return (<Chip startContent={<LiteratureIcon/>} color="default" style={{color:'white', backgroundColor:'#403D58', marginBottom: '0.5rem', marginRight:'0.4rem'}}>
            Literature
          </Chip>);
          case "econ":
            return (<Chip startContent={<EconIcon/>} style={{color:'white', backgroundColor: '#EA9010', marginBottom: '0.5rem', marginRight:'0.4rem'}}>
            Economics
          </Chip>);
          case "economics":
            return (<Chip startContent={<EconIcon/>} style={{color:'white', backgroundColor: '#EA9010', marginBottom: '0.5rem', marginRight:'0.4rem'}}>
            Economics
          </Chip>);
          case "art":
            return (<Chip className="bg-gradient-to-tr" startContent={<ArtIcon/>} style={{color:'white', backgroundColor:'#B85B19', marginBottom: '0.5rem', marginRight:'0.4rem'}}>
            Art
          </Chip>);
          case "music":
            return (<Chip startContent={<MusicIcon/>} style={{color:'white', backgroundColor: '#175676', marginRight:'0.4rem'}}>
            Music
          </Chip>);
          default:
            return undefined              
     }
}

export function evaluateMathQuestion(question:any, freeAnswer:string) : {correct:boolean, correctAnswer: string} {
  if (question.correctAnswer == 0 && question.answerChoiceA != ""){
      let evaluatedAnswer = (question.answerChoiceA as string).replace(" ", "");
      let evaluatedInput = freeAnswer.replace(" ", "");

      return {
          correct: evaluatedAnswer == evaluatedInput,
          correctAnswer: question.answerChoiceA
      }
  }else{
      return {
          correct: Number.parseFloat(question.correctAnswer) == Number.parseFloat(freeAnswer),
          correctAnswer: question.correctAnswer
      }
  }
}