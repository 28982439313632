import { ModalHeader, Modal, Divider, ModalFooter, ModalContent, ModalBody, Calendar, Spinner, CalendarDate, DateValue } from "@nextui-org/react";
import { User } from "../models/user";
import { useEffect, useState } from "react";
import { getOrganizationEvents } from "../network/user_api";

interface ManageOrganizationModalProps {
    loggedInUser : User;
    org : any;
    onDismiss: () => void;
}

const ViewOrganizationModal = ({loggedInUser, org, onDismiss} : ManageOrganizationModalProps) => {
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState<DateValue>();
    const [events, setEvents] = useState<any[]>([]);

    async function grabOrganizationEvents() {
      try {
          setLoading(true);

          const f = await getOrganizationEvents();
          setEvents(f.events);
          console.log(f);
          
          console.log(events);
          
      } catch (error) {
        
      } finally {
        setLoading(false);
      }
    }

    useEffect(() => {
      grabOrganizationEvents()
    }, [])

    return (
        <Modal isOpen={true} onClose={onDismiss} style={{minHeight:'10rem'}}>
            <ModalContent>
              {() => (
                !loading ?
                <>
                  <ModalHeader className="flex flex-col gap-1" style={{color:"white"}}>{org.name}
                  <p style={{color:'gray', fontSize:15}}>{org.sender}</p>
                  <p style={{color:'gray', fontSize:15}}>Created {new Date(org.dateCreated).getMonth() + "/" + new Date(org.dateCreated).getDate() + "/" + new Date(org.dateCreated).getFullYear().toString().substring(2)}</p>
                  </ModalHeader>
    
                  <Divider style={{width:'90%', margin:'auto', justifyContent:'center'}}/>
                  
                  <ModalBody className="flex flex-col gap-1 mb-2 mt-1">
                    <Calendar  color="foreground" onChange={setSelectedDate}/>
                  </ModalBody>
    
                  <Divider style={{width:'90%', margin:'auto', justifyContent:'center'}}/>
    
                  <ModalFooter className="flex flex-col">
                  <div style={{maxHeight:'200px', overflow:'auto'}}>
                    {
                      // TODO FFS clean this shit up
                    selectedDate && events.filter((v) => {return new Date(v.dateTime).getUTCDate() === selectedDate.toDate("UTC").getUTCDate() && new Date(v.dateTime).getUTCMonth() === selectedDate.toDate("UTC").getUTCMonth() && new Date(v.dateTime).getUTCFullYear() === selectedDate.toDate("UTC").getUTCFullYear()}).map((v) => {
                      return (
                        <>
                          <p style={{color:'white', fontSize:20, fontWeight:600}}>{v.eventName} - {new Date(v.dateTime).toLocaleTimeString()}</p>
                          <p style={{color:'white', fontSize:13}}>{v.eventDescription}</p> 
                          <Divider className="mt-2 mb-2"/>
                      </>
                    )
                    })}
                    </div>
      
                    {selectedDate && events.filter((v) => {return new Date(v.dateTime).getUTCDate() === selectedDate.toDate("UTC").getUTCDate() && new Date(v.dateTime).getUTCMonth() === selectedDate.toDate("UTC").getUTCMonth() && new Date(v.dateTime).getUTCFullYear() === selectedDate.toDate("UTC").getUTCFullYear()}).length === 0
                    && <p style={{color:'gray'}}>No events planned on this date.</p>}
                    
                  </ModalFooter>
                </>
                :
                <>
                  <Spinner color="secondary"/>
                </>
              )}
            </ModalContent>
          </Modal>);
}

export default ViewOrganizationModal;