import { useEffect, useState } from "react";
import {Tab, Spinner, Card, Divider, Avatar, Button, CardBody, CardHeader,  Slider, Tabs, Radio, RadioGroup, Input} from "@nextui-org/react";
import { Question as QuestionModel } from '../models/question';
import Question from "./Question";
import * as UserApi from "../network/user_api";
import { User } from "../models/user";
import EconIcon from "./Icons/EconIcon";
import RightArrowIcon from "./Icons/RightArrowIcon";
import LeftArrowIcon from "./Icons/LeftArrowIcon";
import { evaluateMathQuestion } from "../util/categoryIconHelper";

interface QuestionPageLoggedInProps {
  user : User,
  category: string,
  questionCount: number,
  liveCorrections: Boolean,
  showPageNum: Boolean,
  competitiveMode: Boolean,
  practiceTestCode: String,
  sections: Number[]
}

const QuestionPageLoggedInView = (props : QuestionPageLoggedInProps) => {
    const [question, setQuestion] = useState<QuestionModel>({
        "question": "LOADING QUESTION",
        "answerChoiceA": "ANSWER A",
        "answerChoiceB": "ANSWER B",
        "answerChoiceC": "ANSWER C",
        "answerChoiceD": "ANSWER D",
        "answerChoiceE": "ANSWER E",
        "correctAnswer": 1,
        "pageNumberStart": 20,
        "pageNumberEnd": 20,
        section: 1,
        category: "CATEGORY",
        justification: "JUSTIFICATION"
      });

      const answerArr = ["a", "b", "c", "d", "e"];
    
      const [questionLoading, setQuestionLoading] = useState(true);
      const [showQuestionLoadingError, setShowQuestionLoadingError] = useState(false);
      const [chosenAnswer, setChosenAnswer] = useState(-1);
      const [answered, setAnswered] = useState(false);
      const [questionPosition, setQuestionPosition] = useState(0);

      const [answerInputs, setAnswerInputs] = useState<Number[]>([]);
      const [freeAnswerInputs, setFreeAnswerInputs] = useState<string[]>([]);

      const [reviewQuestion, setReviewQuestion] = useState(0);

      const [score, setScore] = useState(0);
      const [scoreCorrect, setScoreCorrect] = useState(0);
      const [scoreAnswered, setScoreAnswered] = useState(0);

      const [correctAnswers, setCorrectAnswers] = useState(0);

      const [quizComplete, setQuizComplete] = useState(false);
      const[questions, setQuestions] = useState<QuestionModel[]>([
        
      ]);

      const [practiceTest, setPracticeTest] = useState<any>();


    function containsQuestion(qArr:QuestionModel[], q : QuestionModel) {
      qArr.forEach(qE => {
        if (qE.question === q.question) return true;
      });

      return false;
    }

    async function grabScore() {
      try {
        const scoreRes = await UserApi.completeTest();

        setScore((scoreRes.correctAmt / scoreRes.answered) * 1000);
        setScoreCorrect(scoreRes.correctAmt);
        setScoreAnswered(scoreRes.answered);
        setQuestions(scoreRes.questions)

        console.log(scoreRes);
        
      } catch (error) {
        console.log(error);
      }
    }
 
    async function loadQuestion(){
      try {
        setShowQuestionLoadingError(false);
        setQuestionLoading(true);

        let questionArray: string | any[] | ((prevState: QuestionModel[]) => QuestionModel[]) = [];
        

        let response = await UserApi.generateTest({
          categories: [props.category],
          competitiveMode: props.competitiveMode as boolean,
          hasPageNumbers: props.showPageNum as boolean,
          questionCount: props.questionCount,
          liveCorrections: props.liveCorrections as boolean,
          practiceTestCode: props.practiceTestCode,
          sections: props.sections
        });

        let v = response;

        console.log(v);
        
        
        
        setQuestions(v.questions);

        if (v.practiceTest){
          setPracticeTest(v.practiceTest);
        }
        
        setQuestion(questionArray[0]);
        setAnswered(false);
      } catch (error) {
        console.log(error);
        alert(error)
        setShowQuestionLoadingError(true);
      } finally {
        setQuestionLoading(false);
      }
      
    }
      
  useEffect(() => {
    loadQuestion();
  }, []);

    return ( 
        <>
            {questionLoading && <><Spinner color="secondary" style={{marginLeft:"auto", marginRight:"auto", justifyContent:"center", display:"flex"}}/><h1 style={{margin:'auto', padding:'2rem', justifyContent:'center'}}>Setting Up Test...</h1></>}
            {showQuestionLoadingError}
            {!questionLoading && !showQuestionLoadingError && !quizComplete && <><Question 
            question={questions[questionPosition]}
            isMathQuestion={props.category === 'math'}
            liveCorrections={practiceTest ? practiceTest.liveCorrections : props.liveCorrections}
            currentQuestionCount={practiceTest ? practiceTest.questionCount - questionPosition : props.questionCount - questionPosition}
            fullQuestionAmount={practiceTest ? practiceTest.questionCount : props.questionCount}
            answeredSoFar={questionPosition + 1}
            competitiveMode={practiceTest ? false : props.competitiveMode}
            showPageNum={practiceTest ? practiceTest.pageNumbers : props.showPageNum}
            onAnsOneClick={() => {setChosenAnswer(0)}}
            onAnsTwoClick={() => {setChosenAnswer(1)}}
            onAnsThreeClick={() => {setChosenAnswer(2)}}
            onAnsFourClick={() => {setChosenAnswer(3)}}
            onAnsFiveClick={() => {setChosenAnswer(4)}}
            onSubmitClick={(isCorrect: boolean) => {
              if (answered) return;

              setAnswered(true);
              

              if (isCorrect){
                setCorrectAnswers(correctAnswers + 1);
              }else{
              }

            }}
            onNextQuestionClick={(freeAnswer? : string)=> {
              
              if (freeAnswer){
                setFreeAnswerInputs(freeAnswerInputs.concat(freeAnswer))
              }else{
                setAnswerInputs(answerInputs.concat(chosenAnswer));
              }

              setQuestionPosition(questionPosition + 1);
              
              if (questionPosition === (practiceTest ? practiceTest.questionCount : props.questionCount) - 1){
                setQuizComplete(true);
                grabScore();
              }else{
                setAnswered(false);
                
                
                setQuestion(questions[questionPosition + 1]);
              }
            }}
          
            ></Question></>}

            {quizComplete && 
              
              <>
              <Card style={{width:'24rem', justifyContent:'center', margin:'auto', fontWeight:600}}>
                <CardHeader>
                  <h1 style={{color:"#E0FBFC", justifyContent:'center', margin:'auto'}}>Quiz Complete!</h1>
                </CardHeader>
                <Divider style={{width:'90%', justifyContent:'center', margin:'auto'}}/>
                <CardBody style={{justifyContent:'center', margin:'auto'}}>
                  
                  <h1 style={{color:"#E0FBFC", justifyContent:'center', margin:'auto', fontSize:30}}>Scored: {Math.round(score)} ({scoreCorrect} / {scoreAnswered})</h1>
                </CardBody>
              </Card>
              
          {props.questionCount > 1 && questions && reviewQuestion < questions.length && 
          <> 
          <Card className="mt-4 pb-3" style={{width:'60rem', justifyContent:'center', margin:'auto'}} >
            <CardHeader className="flex justify-center items-center mb-2 h-3/6">
              <div className="flex justify-center items-center mb-1 h-3/6">
                <p style={{justifyContent:'center', margin:'auto', fontWeight:600}}>Review Questions</p>
              </div>
            </CardHeader>
            <Divider style={{width:'90%', justifyContent:'center', margin:'auto'}}/>
            <CardBody>
            <Slider
            style={{marginLeft:"auto", marginRight:"auto", justifyContent:"center", display:"flex"}}
            size="sm"
            step={1}
            color="foreground"
            
            showSteps={true}
            value={reviewQuestion + 1}
            onChange={(e) => {setReviewQuestion(e as number - 1)}}
            maxValue={props.questionCount}
            minValue={1}
            defaultValue={1}
            startContent={<Button onClick={() => {setReviewQuestion(reviewQuestion - 1 >= 0 ? reviewQuestion - 1 : reviewQuestion)}} isIconOnly><LeftArrowIcon/></Button>}
            endContent={<Button onClick={() => {setReviewQuestion(reviewQuestion + 1 < props.questionCount ? reviewQuestion + 1 : reviewQuestion)}} isIconOnly><RightArrowIcon/></Button>}
            className="max-w-md" />

            
            

            <div className="flex w-full flex-col">
                    <Tabs aria-label="Options" style={{marginLeft:"auto", marginRight:"auto"}}>
                        <Tab key="questionLabel" title="Question">
                            <Card style={{justifyContent:"center", width:"40rem", marginLeft:"auto", marginRight:"auto"}}>
                                    <CardBody >
                                    <p className="font-bold text-inherit text-balance" style={{textWrap:"pretty", marginRight:"auto", marginLeft:"auto", textAlign:"center"}}>{reviewQuestion + 1}. {questions[reviewQuestion].question}</p>
                                    </CardBody>
                                </Card>
                        
                        </Tab>
                        {<Tab isDisabled={questions[reviewQuestion].pageNumberStart === -1} key="page" title="Page Number">
                            <Card style={{justifyContent:"center", width:"19rem", height:"auto", marginLeft:"auto", marginRight:"auto"}}>
                                    <CardBody>
                                        <h2 style={{justifyContent:"center", marginLeft:"auto", marginRight:"auto", fontSize:45, marginTop:"auto", marginBottom:"0.6rem"}}>{(questions[reviewQuestion].pageNumberEnd === questions[reviewQuestion].pageNumberStart) ? questions[reviewQuestion].pageNumberStart?.toString(): questions[reviewQuestion].pageNumberStart?.toString() + " - " + questions[reviewQuestion].pageNumberEnd?.toString() }</h2>
                                    </CardBody>
                                </Card>
                        </Tab>}
                        {<Tab isDisabled={questions[reviewQuestion].justification === "" || questions[reviewQuestion].justification === undefined} key="justification" title="Justification">
                        <Card style={{justifyContent:"center", width:"40rem", marginLeft:"auto", marginRight:"auto"}}>
                                    <CardBody >
                                    <p className="font-bold text-inherit text-balance" style={{textWrap:"pretty", marginRight:"auto", marginLeft:"auto", textAlign:"center"}}>{questions[reviewQuestion].justification}</p>
                                    </CardBody>
                                </Card>
                        </Tab>}
                        
                    </Tabs>



                    </div>

                    {
                    
                    props.category === "math" ?
                       (evaluateMathQuestion(questions[reviewQuestion], freeAnswerInputs[reviewQuestion]).correct)?  
                       <div className="flex justify-center items-center w-1/3 h-3/6" style={{marginBottom:"auto", width:'40%', justifyContent:'center', textAlign:'center', margin:'auto'}}>
                       <Input label="My Answer (Correct)" style={{color:'#5BDD68'}} isReadOnly value={freeAnswerInputs[reviewQuestion]}/>
                       </div>
                        
                            :
                            <Tabs style={{justifyContent:'center', margin:'auto'}}>
                                <Tab title="Correct Answer">
                                <div className="flex justify-center items-center w-1/3 h-3/6" style={{marginBottom:"auto", width:'40%', justifyContent:'center', textAlign:'center', margin:'auto'}}>
                                    <Input label="Correct Answer" style={{color:'#5BDD68'}} isReadOnly value={evaluateMathQuestion(questions[reviewQuestion], freeAnswerInputs[reviewQuestion]).correctAnswer}/>
                                </div>
                                </Tab>
                                <Tab title="My Answer (Incorrect)">
                                <div className="flex justify-center items-center w-1/3 h-3/6" style={{marginBottom:"auto", width:'40%', justifyContent:'center', textAlign:'center', margin:'auto'}}>
                                    <Input label="My Answer (Incorrect)" isReadOnly value={freeAnswerInputs[reviewQuestion]}/>
                                </div>
                                </Tab>
                            </Tabs>
                            


                      :
                    answerInputs[reviewQuestion] != questions[reviewQuestion].correctAnswer ?
                      <Tabs style={{justifyContent:'center', margin:'auto'}}>
                      <Tab title="My Answer (Incorrect)">
                        <div className="flex justify-center items-center h-3/6" style={{marginBottom:"auto"}}>
                          <RadioGroup id="radioGroup" value={answerArr[answerInputs[reviewQuestion] as number]}>
                              <Radio value="a" color={questions[reviewQuestion].correctAnswer == 0 ? "success" : "default"}  >A. {questions[reviewQuestion].answerChoiceA}</Radio>
                              <Radio value="b" color={questions[reviewQuestion].correctAnswer == 1 ? "success" : "default"} >B. {questions[reviewQuestion].answerChoiceB}</Radio>
                              <Radio value="c" color={questions[reviewQuestion].correctAnswer == 2 ? "success" : "default"}  >C. {questions[reviewQuestion].answerChoiceC}</Radio>
                              <Radio value="d" color={questions[reviewQuestion].correctAnswer == 3 ? "success" : "default"} >D. {questions[reviewQuestion].answerChoiceD}</Radio>
                              <Radio value="e" color={questions[reviewQuestion].correctAnswer == 4 ? "success" : "default"} >E. {questions[reviewQuestion].answerChoiceE}</Radio>
                          </RadioGroup>
                        </div>
                      </Tab>
                      <Tab title="Correct Answer">
                        <div className="flex justify-center items-center h-3/6" style={{marginBottom:"auto"}}>
                          <RadioGroup id="radioGroup" value={answerArr[questions[reviewQuestion].correctAnswer as number]}>
                              <Radio value="a" color={questions[reviewQuestion].correctAnswer == 0 ? "success" : "default"}  >A. {questions[reviewQuestion].answerChoiceA}</Radio>
                              <Radio value="b" color={questions[reviewQuestion].correctAnswer == 1 ? "success" : "default"} >B. {questions[reviewQuestion].answerChoiceB}</Radio>
                              <Radio value="c" color={questions[reviewQuestion].correctAnswer == 2 ? "success" : "default"}  >C. {questions[reviewQuestion].answerChoiceC}</Radio>
                              <Radio value="d" color={questions[reviewQuestion].correctAnswer == 3 ? "success" : "default"} >D. {questions[reviewQuestion].answerChoiceD}</Radio>
                              <Radio value="e" color={questions[reviewQuestion].correctAnswer == 4 ? "success" : "default"} >E. {questions[reviewQuestion].answerChoiceE}</Radio>
                          </RadioGroup>
                        </div>
                      </Tab>
                    </Tabs>
                    :
                    <Tabs style={{justifyContent:'center', margin:'auto'}}>
                      <Tab title="Answer">
                        <div className="flex justify-center items-center h-3/6" style={{marginBottom:"auto"}}>
                          <RadioGroup id="radioGroup" value={answerArr[answerInputs[reviewQuestion] as number]}>
                              <Radio value="a" color={questions[reviewQuestion].correctAnswer == 0 ? "success" : "default"}  >A. {questions[reviewQuestion].answerChoiceA}</Radio>
                              <Radio value="b" color={questions[reviewQuestion].correctAnswer == 1 ? "success" : "default"} >B. {questions[reviewQuestion].answerChoiceB}</Radio>
                              <Radio value="c" color={questions[reviewQuestion].correctAnswer == 2 ? "success" : "default"}  >C. {questions[reviewQuestion].answerChoiceC}</Radio>
                              <Radio value="d" color={questions[reviewQuestion].correctAnswer == 3 ? "success" : "default"} >D. {questions[reviewQuestion].answerChoiceD}</Radio>
                              <Radio value="e" color={questions[reviewQuestion].correctAnswer == 4 ? "success" : "default"} >E. {questions[reviewQuestion].answerChoiceE}</Radio>
                          </RadioGroup>
                        </div>
                      </Tab>
                    </Tabs>
                    }
                    </CardBody>
                    </Card>
            </>
          }

          
          </>
            }
        </>
        
     );
}
 
export default QuestionPageLoggedInView;